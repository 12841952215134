import React from "react";
import Loadable from "react-loadable";
import Layout from "./theme/Development/Layout";
import Loading from "../src/components/common/loading/loading";

function DoLoading() {
  return <Loading isLoad={true} />;
}

// const Login = React.lazy(() => import("./components/auth/login"));

// const Home = React.lazy(() => import("./components/home/home"));

// const Transfer = React.lazy(() => import("./components/point/transfer"));

// const Transaction = React.lazy(() => import("./components/point/transaction"));

// const AccountTransaction = React.lazy(() =>
//   import("./components/account/transaction")
// );

const Login = Loadable({
  loader: () => import("./components/auth/login"),
  loading: DoLoading,
});

const Home = Loadable({
  loader: () => import("./components/home/home"),
  loading: DoLoading,
});

const Transfer = Loadable({
  loader: () => import("./components/point/transfer"),
  loading: DoLoading,
});

const Transaction = Loadable({
  loader: () => import("./components/point/transaction"),
  loading: DoLoading,
});

const AccountTransaction = Loadable({
  loader: () => import("./components/account/transaction"),
  loading: DoLoading,
});

const Luckydraw = Loadable({
  loader: () => import("./components/luckydraw/luckydraw"),
  loading: DoLoading,
});

const LuckydrawForm = Loadable({
  loader: () => import("./components/luckydraw/luckydrawForm"),
  loading: DoLoading,
});

const LuckydrawPrint = Loadable({
  loader: () => import("./components/luckydraw/luckydrawPrint"),
  loading: DoLoading,
});

const LuckydrawTransferHistory = Loadable({
  loader: () => import("./components/luckydraw/luckydrawTransferHistory"),
  loading: DoLoading,
});

const LuckydrawTransfer = Loadable({
  loader: () => import("./components/luckydraw/luckydrawTransfer"),
  loading: DoLoading,
});

const ProductCoupon = Loadable({
  loader: () => import("./components/productCoupon/productCoupon"),
  loading: DoLoading,
});

const ProductCouponTransaction = Loadable({
  loader: () => import("./components/productCoupon/productCouponTransaction"),
  loading: DoLoading,
});

const ProductCouponSale = Loadable({
  loader: () => import("./components/productCoupon/productCouponSale"),
  loading: DoLoading,
});

const ProductCouponUsedSale = Loadable({
  loader: () => import("./components/productCoupon/productCouponUsedSale"),
  loading: DoLoading,
});

const ChangePassword = Loadable({
  loader: () => import("./components/user/changePassword"),
  loading: DoLoading,
});

const routes = [
  { path: "", exact: true, name: "Development", component: Layout },
  { path: "/login", name: "Login", component: Login },
  { path: "/home", name: "Home", component: Home },
  { path: "/transfer", name: "Transfer", component: Transfer },
  { path: "/transactions", name: "Transactions", component: Transaction },
  {
    path: "/accountTransactions",
    name: "AccountTransaction",
    component: AccountTransaction,
  },
  { path: "/luckydraw", name: "Lucky Draw", component: Luckydraw },
  { path: "/luckydrawForm", name: "Lucky Draw", component: LuckydrawForm },
  {
    path: "/luckydrawPrint",
    name: "Lucky Draw Print",
    component: LuckydrawPrint,
  },

  {
    path: "/luckydrawTransferHistory",
    name: "Luckydraw Transfer History",
    component: LuckydrawTransferHistory,
  },

  {
    path: "/luckydrawTransfer",
    name: "Lucky Draw Transfer",
    component: LuckydrawTransfer,
  },
  { path: "/productCoupon", name: "Product Coupon", component: ProductCoupon },
  {
    path: "/productCouponTransaction",
    name: "Product Coupon Transaction",
    component: ProductCouponTransaction,
  },
  {
    path: "/ProductCouponSale",
    name: "Product Coupon Transaction",
    component: ProductCouponSale,
  },
  {
    path: "/ProductCouponUsedSale",
    name: "Product Coupon Transaction",
    component: ProductCouponUsedSale,
  },
  {
    path: "/changePassword",
    name: "Change Password",
    component: ChangePassword,
  },
];

export default routes;
