import React from "react";

const Input = ({ name, label, error, ...rest }) => {
  return (
    <React.Fragment>
      <input
        {...rest}
        name={name}
        id={name}
        className="form-control"
        aria-describedby={name}
      />
      {error && (
        <div className="text-danger help-block">
          <small>{error}</small>
        </div>
      )}
    </React.Fragment>
  );
};

export default Input;
