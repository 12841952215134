import React, { Component } from "react";
import RegisterView from "../../theme/Development/auth/RegisterView";

class Register extends Component {
  render() {
    return (
      <React.Fragment>
        <RegisterView />
      </React.Fragment>
    );
  }
}
export default Register;
