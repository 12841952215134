import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "../input/input";
import IconInput from "../iconInput/iconInput";
import Select from "../select/select";
import SelectWithOptionGroup from "../select/selectWithOptionGroup";
import Checkbox from "../checkbox/checkboxSwitch";
import DateInput from "../dateInput/dateInput";
import DropdownDateInput from "../dateInput/dropdownDateInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSync } from "@fortawesome/free-solid-svg-icons";
import RadioGroup from "../radioGroup/radioGroup";
import TextArea from "../textarea/textarea";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    isLoading: false,
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.setState({ isLoading: false });
      return;
    }
    this.doSubmit();
    this.setState({ isLoading: false });
  };

  renderCancelButton(label, route, state) {
    return (
      <>
        <button
          id="cancel"
          className="btn btn-warning ml-3"
          style={{ cursor: "pointer" }}
          aria-label="Close"
          onClick={() =>
            this.props.history.push({ pathname: route, state: state })
          }
        >
          <i className="fa fa-times mr-2" />
          {label}
        </button>
      </>
    );
  }

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    data[input.name] = input.value;
    if (input.name.includes("confirmPassword")) {
      if (data.confirmPassword != undefined) {
        if (data[input.name] === data.password) {
          delete errors[input.name];
        }
      }
    }
    this.setState({ data, errors });
  };

  handleCheckboxChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const validInput = { name: input.name, value: input.checked };
    const errorMessage = this.validateProperty(validInput);
    if (errorMessage) errors[input.name] = errorMessage;
    const data = { ...this.state.data };
    if (input.checked) {
      delete errors[input.name];
      data[input.name] = input.checked = 1;
      this.setState({ data, errors });
    } else {
      data[input.name] = input.checked = 0;
      delete errors[input.name];
      this.setState({ data, errors });
    }
  };

  handleRadioChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const validInput = { name: input.name, value: input.value };
    const errorMessage = this.validateProperty(validInput);
    if (errorMessage) errors[input.name] = errorMessage;
    const data = { ...this.state.data };
    if (input.value) {
      delete errors[input.name];
      data[input.name] = input.value;
      this.setState({ data, errors });
    }
  };

  handleDateChange = (date, name) => {
    const errors = { ...this.state.errors };
    const validInput = { name: name, value: date };
    const errorMessage = this.validateProperty(validInput);
    if (errorMessage) errors[name] = errorMessage;

    const { data } = this.state;
    data[name] = date;
    this.setState({ data, errors });
  };

  renderButton(label, buttonstyle, loading = false, disabled = false) {
    return (
      <button
        disabled={
          this.validate() != null || this.state.isLoading || loading || disabled
        }
        className={buttonstyle === undefined ? "btn btn-primary" : buttonstyle}
      >
        {label}
        {this.state.isLoading || loading ? (
          <FontAwesomeIcon className="ml-2" icon={faSpinner} pulse />
        ) : null}
      </button>
    );
  }

  renderCheckbox(name, label, type = "checkbox") {
    const { data, errors } = this.state;
    return (
      <Checkbox
        type={type}
        name={name}
        checked={data[name]}
        label={label}
        onChange={this.handleCheckboxChange}
        error={errors[name]}
      />
    );
  }

  renderDateInput(name, label, dateFormat) {
    const { data, errors } = this.state;
    return (
      <DateInput
        name={name}
        value={Date.parse(data[name])}
        label={label}
        dateFormat={dateFormat}
        onChange={(e) => this.handleDateChange(e, name)}
        error={errors[name]}
      />
    );
  }

  renderDropdownDateInput(name, label, format) {
    const { data, errors } = this.state;
    return (
      <DropdownDateInput
        name={name}
        value={new Date(data[name])}
        label={label}
        format={format}
        onChange={(e) => this.handleDateChange(e, name)}
        error={errors[name]}
      />
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderSelectWithOptionGroup(name, label, options) {
    const { data, errors } = this.state;
    return (
      <SelectWithOptionGroup
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, placeholder = "", type = "text", className) {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        className={className}
        placeholder={placeholder}
      />
    );
  }

  renderTextArea(name, label, placeholder = "", className) {
    const { data, errors } = this.state;
    return (
      <TextArea
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        className={className}
        placeholder={placeholder}
      />
    );
  }

  renderInputPassword(name, label, placeholder) {
    const { data, errors } = this.state;
    return (
      <Input
        type={"password"}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        placeholder={placeholder}
      />
    );
  }

  renderIconInput(name, label, iconName, type = "text") {
    const { data, errors } = this.state;
    return (
      <IconInput
        type={type}
        name={name}
        value={data[name]}
        label={label}
        iconName={iconName}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderStar(name) {
    const { errors, data } = this.state;
    let _className = "text-danger ml-2";
    if (data[name] !== undefined) {
      if (data[name] !== "") {
        if (errors[name] === undefined) {
          _className = "text-success ml-2";
        }
      }
    }
    return <span className={_className}> * </span>;
  }

  renderRadioGroup(name, label, options, value = "", className = "") {
    const { data, errors } = this.state;
    return (
      <RadioGroup
        name={name}
        label={label}
        options={options}
        //checked={value == data[name] ? true : false}
        onChange={this.handleRadioChange}
        error={errors[name]}
        data={data}
        value={value == "" ? data[name] : value}
        className={className}
      />
    );
  }
}

export default Form;
