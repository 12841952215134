import React, { Component } from "react";
import { Link } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import menuIcon from "@iconify/icons-mdi/menu";
import closeIcon from "@iconify/icons-mdi/close";
import userIcon from "@iconify/icons-mdi/user";
import homeIcon from "@iconify/icons-mdi/home";
import password from "@iconify/icons-mdi/password";

import logoutIcon from "@iconify/icons-mdi/logout";
import { CommonContext } from "../../context/CommonContext";
import OffCanvas from "react-aria-offcanvas";
import logo from "../../assets/logo.png";
import auth from "../../components/auth/authService";

class Header extends Component {
  static contextType = CommonContext;
  state = {
    isOpen: false,
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { common } = this.props;
    //const { languageData } = this.context;
    return (
      <React.Fragment>
        <header>
          <div className="container">
            <div className="row">
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <div>
                  <button
                    aria-label="Menu"
                    aria-controls="menu"
                    aria-expanded={this.state.isOpen}
                    onClick={this.open}
                    style={offcanvasMenuStyles.menuButton}
                  >
                    <InlineIcon
                      icon={menuIcon}
                      style={offcanvasMenuStyles.icon}
                    />
                  </button>
                </div>
                <div>
                  <Link to={`/home`} className="header-logo-text">
                    <img
                      src={logo}
                      alt=""
                      className="header-image rounded-circle ml-n5"
                    />
                  </Link>
                </div>
                <div>
                  {/* <Link to={"/cartList"} className="cart-holder">
                    <div className="cart-count">
                      <span>{common.cartProductCountValue}</span>
                    </div>
                    <div className="icon-holder">
                      <InlineIcon icon={basketIcon} className="icon" />
                    </div>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </header>

        <OffCanvas
          isOpen={this.state.isOpen}
          onClose={this.close}
          labelledby="menu-button"
          height="100%"
          style={offcanvasMenuStyles}
        >
          <div className="offcanvas-title">
            <span>Menu</span>
          </div>
          <button
            onClick={this.close}
            style={offcanvasMenuStyles.menuButtonClose}
          >
            <InlineIcon icon={closeIcon} className="icon mr-2" />
          </button>
          <nav id="menu">
            <ul style={offcanvasMenuStyles.OffCanvasMenuList}>
              <li>
                <Link
                  to={"/home"}
                  onClick={this.close}
                  className="offcanvas-menu_link"
                >
                  <span className="offcanvas-menu_icon">
                    <InlineIcon icon={homeIcon} className="icon mr-2" />
                  </span>
                  <span>Home</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/changePassword"}
                  onClick={this.close}
                  className="offcanvas-menu_link"
                >
                  <span className="offcanvas-menu_icon">
                    <InlineIcon icon={password} className="icon mr-2" />
                  </span>
                  <span>Change Password</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/login"}
                  className="offcanvas-menu_link"
                  onClick={() => auth.logout()}
                >
                  <span className="offcanvas-menu_icon">
                    <InlineIcon icon={logoutIcon} className="icon mr-2" />
                  </span>
                  <span>Logout</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to={"/"}
                  onClick={this.close}
                  className="offcanvas-menu_link"
                >
                  <span className="offcanvas-menu_icon">
                    <InlineIcon icon={fileIcon} className="icon mr-2" />
                  </span>
                  <span>Personal Information</span>
                </Link>
              </li>
              <li>
                <Link
                  to={"/"}
                  onClick={this.close}
                  className="offcanvas-menu_link"
                >
                  <span className="offcanvas-menu_icon">
                    <InlineIcon icon={reportIcon} className="icon mr-2" />
                  </span>
                  <span>Sale Report</span>
                </Link>
              </li> */}
            </ul>
          </nav>
        </OffCanvas>
      </React.Fragment>

      // <header>
      //   <div className="d-flex flex-row justify-content-between align-items-center">
      //     <div className="page-title-holder">
      //       <Link to={"/"}>
      //         {" "}
      //         <img src={""} alt="" className="header-logo" />
      //       </Link>
      //       <Link to={"/"} className="header-logo-text">
      //         Easy Clickup
      //       </Link>
      //     </div>

      //     <Link to={"/cartList"} className="cart-holder">
      //       <div className="cart-count">
      //         <span>{common.cartProductCountValue}</span>
      //       </div>
      //       <div className="icon-holder">
      //         <InlineIcon icon={CartIcon} className="icon" />
      //       </div>
      //       <div className="text">Cart</div>
      //     </Link>
      //   </div>
      // </header>
    );
  }
}

const offcanvasMenuStyles = {
  content: {
    background: "#f9f9f9",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.25)",
    zIndex: "999",
  },
  menuButton: {
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "22px",
    padding: 15,
  },
  icon: {
    color: "#fff",
  },
  menuBtnIcon: {
    color: "#fff",
    fontSize: 18,
  },
  menuButtonClose: {
    position: "absolute",
    top: 4,
    right: 0,
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    padding: 10,
    fontSize: 25,
    color: "#fff",
  },
  OffCanvasMenuList: {
    listStyle: "none",
    paddingLeft: 0,
  },
};

export default Header;
