import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CommonContext } from "../../../context/CommonContext";
import Joi from "joi-browser";
import auth from "../../../components/auth/authService";
import logo from "../../../assets/logo.png";
import Form from "../../../components/common/form/form";
import { NotificationManager } from "react-notifications";
//import { InlineIcon } from "@iconify/react";
// npm install --save-dev @iconify/react @iconify-icons/simple-icons
import { Icon, InlineIcon } from "@iconify/react";
import UserIcon from "@iconify/icons-mdi/user";
import EyeIcon from "@iconify/icons-mdi/eye";
import CloseIcon from "@iconify/icons-mdi/close";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

import MyModal from "../../../components/common/modal/modal";

class LoginView extends Form {
  //static contextType = CommonContext;
  state = {
    data: { phoneNo: "", password: "" },
    errors: {},
    isLoad: false,
  };

  schema = {
    phoneNo: Joi.alternatives()
      .required()
      .try(
        Joi.string()
          .regex(/([0-9\s\-]{8,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/)
          .error(() => {
            return { message: "Wrong id." };
          })
      ),
    password: Joi.string()
      .required()
      .error(() => {
        return { message: "Wrong password." };
      }),
  };

  componentDidMount() {
    if (auth.getToken()) {
      this.props.history.replace("/");
    }
  }

  doSubmit = async () => {
    this.setState({ isLoad: true });
    try {
      const { data } = this.state;
      const response = await auth.login(data.phoneNo, data.password);
      if (auth.getToken()) {
        //MyModal.SuccessModal("Success!!!!", "Login success!");
        this.props.history.replace("/home");
      } else {
        console.log(response);
      }
    } catch (ex) {
      let modalContent = "";
      //const errors = { ...this.state.errors };
      //const { languageData } = this.context;
      //MyModal.SuccessModal("Success!", "Login success!");

      NotificationManager.error("ID or password wrong!");
      //errors.all = this.context.languageData.omOrPasswordIsIncorrect;

      this.setState({ data: { phoneNo: "", password: "" } });
    }
    this.setState({ isLoad: false });
  };

  render() {
    //const { languageData } = this.context;
    const { isLoad } = this.state;
    return (
      <React.Fragment>
        <Fade>
          <section className="content-section bg-purple">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <Zoom>
                    <div className="text-center mb-5">
                      <img
                        src={logo}
                        alt=""
                        className="login-image rounded-circle mt-3 mb-4"
                      />
                    </div>
                  </Zoom>
                  <div className="login-card">
                    <div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          {this.renderInput(
                            "phoneNo",
                            "Member Id",
                            "Member Id"
                          )}
                        </div>
                        <div className="form-group">
                          {this.renderInputPassword(
                            "password",
                            "Password",
                            "Password"
                          )}
                        </div>
                        {this.renderButton(
                          "login",
                          "btn btn-login btn-block mr-2",
                          isLoad
                        )}
                      </form>
                    </div>
                  </div>

                  <div className="login-page-links account-reg">
                    <small className="text-white mt-1 mr-2">
                      Don't have an account?{" "}
                    </small>
                    <Link to={"/register"}>
                      <small>Sign up</small>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Fade>
      </React.Fragment>
    );
  }
}

export default LoginView;
