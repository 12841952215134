import React from "react";

const Select = ({ name, label, options, error, ...rest }) => {
  return (
    <React.Fragment>
      <select
        name={name}
        id={name}
        {...rest}
        className="custom-select form-control"
      >
        <option value="">Plese Select ...</option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <small className="text-danger help-block">{error}</small>}
    </React.Fragment>
  );
};

export default Select;
