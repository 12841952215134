import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./authService";

const ProtectedRoute = ({
  path,
  component: Component,
  common: common,
  render,
  ...rest
}) => {
  const token = auth.getToken();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (props.location.pathname === "/") {
          if (token === null || token === undefined) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            return Component ? <Component {...props} /> : render(props);
          }
        } else {
          if (token === null || token === undefined) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            );
          } else {
            return Component ? <Component {...props} /> : render(props);
          }
        }
      }}
    />
  );
};

export default ProtectedRoute;
