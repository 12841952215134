import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ReactHtml_Parser from "react-html-parser";
import "./modal.css";
//import CheckImg from "../../../assets/check.png";
//import DenyImg from "../../../assets/warming.png";

// const checkImage = (type) => {
//   if (type) {
//     return <img src={CheckImg} className="modal-icon" alt="" />;
//   } else {
//     return <img src={DenyImg} className="modal-icon" alt="" />;
//   }
// };

const MyModal = ({
  isOpen,
  toggle,
  modalClassName,
  isModalHeader = true,
  title,
  isCheckImageShow = false,
  type,
  children,
  isFooter = true,
  footerButtonLabel,
  onClickButton,
}) => {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className={modalClassName}>
        {isModalHeader ? (
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
        ) : null}
        <ModalBody>
          {/* {isCheckImageShow ? checkImage(type) : null} */}
          {children}
        </ModalBody>
        {isFooter ? (
          <ModalFooter>
            <Button color="primary" onClick={onClickButton}>
              {footerButtonLabel}
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        ) : null}
      </Modal>
    </div>
  );
};

const SuccessModal = (isOpenModal, description, title) => {
  const toggle = () => {
    isOpenModal = false;
  };
  return (
    <div>
      <Modal
        isOpen={isOpenModal}
        toggle={toggle}
        className="modal-dialog-centered modal-custom"
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {/* <img src={CheckImg} className="modal-icon" alt="" />; */}
          <p>{description}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const WarningModal = (description, title) => {
  var isOpenModel = true;
  const toggle = () => {
    isOpenModel = false;
  };
  return (
    <div>
      <Modal
        isOpen={isOpenModel}
        toggle={toggle}
        className="modal-dialog-centered modal-custom"
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {/* <img src={DenyImg} className="modal-icon" alt="" />; */}
          <p>{description}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

// export default {
//   SuccessModal,
//   WarningModal,
//   MyModal,
// };
export default MyModal;
