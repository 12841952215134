import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginForm from "./theme/Development/auth/loginView";
import Register from "./components/auth/register";
import Layout from "./theme/Development/Layout";
import "./App.css";
import { CommonContext } from "./context/CommonContext";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import ProtectedRoute from "./components/auth/protectedRoute";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);
class App extends React.Component {
  state = {
    common: {
      languageData: {},
    },
    languages: [],
    isLoad: false,
  };

  render() {
    const { isLoad, common } = this.state;
    return !isLoad ? (
      <CommonContext.Provider value={common}>
        <NotificationContainer />
        <BrowserRouter basename="/">
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path={"/login"}
                name="Login Page"
                component={LoginForm}
                common={this.state.common}
              />
              <Route
                exact
                path={"/register"}
                name="Register"
                component={Register}
                common={this.state.common}
              />
              <ProtectedRoute
                path="/"
                name="Home"
                render={(route) => (
                  <Layout
                    state={this.state}
                    goBack={route.history.goBack}
                    route={route}
                  />
                )}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </CommonContext.Provider>
    ) : (
      <div id="loading">
        <img id="loading-image" src={"loadingImage"} alt="Loading..." />
      </div>
    );
  }
}

export default App;
