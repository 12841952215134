import React, { Suspense, Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "../../components/common/loading/loading";
import Header from "./Header";
import routes from "../../routes";
import BottomNavigation from "../../components/navigation/BottomNavigation";
import { TransitionGroup, CSSTransition } from "react-transition-group";

class Layout extends Component {
  state = {
    common: {},
    languages: [],
  };

  loading = () => <Loading isLoad={true} />;

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.state) !== JSON.stringify(state)) {
      return {
        common: props.state.common,
        languages: props.state.languages,
      };
    }
    return null;
  }

  render() {
    return (
      <Suspense fallback={this.loading()}>
        <Header common={this.state.common} />
        <main id="MainWrapper" className="content-section">
          <Suspense fallback={this.loading()}>
            <Route
              render={({ location }) => (
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={1000}
                    className="fade"
                  >
                    <Switch location={location}>
                      {routes.map((route, idx) => {
                        return route.component ? (
                          <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={route.name}
                            render={(props) => <route.component {...props} />}
                          />
                        ) : null;
                      })}
                      <Redirect from="/" to="/home" />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          </Suspense>
        </main>
        {/* <BottomNavigation /> */}
      </Suspense>
    );
  }
}

export default Layout;
