import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DropdownDateInput = ({ name, label, value, format, error, ...rest }) => {
  if (format) {
    return (
      <React.Fragment>
        <DatePicker
          {...rest}
          selected={value}
          name={name}
          id={name}
          dateFormat={format}
          className='form-control'
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
        />
        {error && <small className='text-danger help-block'>{error}</small>}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <DatePicker
          {...rest}
          selected={value}
          name={name}
          dateFormat='DD-MM-YYYY'
          id={name}
          className='form-control'
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
        />
        {error && <small className='text-danger help-block'>{error}</small>}
      </React.Fragment>
    );
  }
};

export default DropdownDateInput;
