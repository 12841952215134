import http from "../../services/httpService";
import { apiUrl } from "../../config.json";
import qs from "qs";

const apiEndpoint = apiUrl + "api/token";
const tokenKey = "W9MemberToken";

http.setToken(getToken());

export async function login(username, password, grant_type = "password") {
  const { data } = await http.post(
    apiEndpoint,
    qs.stringify({
      grant_type: grant_type,
      username: username,
      password: password,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  if (data.data.success) {
    localStorage.setItem(tokenKey, data.data.token);
    localStorage.setItem("userId", data.data.userId.toString());
    localStorage.setItem("memberId", data.data.memberId.toString());
    localStorage.setItem("userName", data.data.userName);
    http.setToken(data.data.token);

    return data;
  } else {
    return data;
  }
}

export async function register(params) {
  const { data: response } = await http.post(
    apiUrl + "/api/member/register",
    params
  );
  if (response.success) {
    const { data } = response;
    localStorage.setItem(tokenKey, data.token);
    http.setToken(getToken());
  }
  return response;
}

export function checkLogin() {
  try {
    const data = localStorage.getItem(tokenKey);
    return data;
  } catch (ex) {
    return null;
  }
}

export function loginWithToken(token) {
  localStorage.setItem(tokenKey, token);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  http.removeToken();
  localStorage.removeItem("userId");
  window.location = "/login";
}

export function getCurrentUser() {
  try {
    const data = JSON.parse(localStorage.getItem("W9MemberData"));
    return data;
  } catch (ex) {
    return null;
  }
}

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export function getUserId() {
  return localStorage.getItem("userId");
}

export function checkToken(token) {
  return http.post(apiUrl + "api/auth/checkToken?token=" + token);
}

export function forgetPassword(data) {
  return http.post(apiUrl + "api/auth/forgetPassword", data);
}

export function resetPassword(data) {
  return http.post(apiUrl + "api/auth/resetPassword", data);
}

export async function getMemberName(memberId) {
  return http.get(apiUrl + "api/member/getMemberName/" + memberId);
}

export async function verifyPhone(data) {
  return http.post(apiUrl + "api/member/verifyPhone", data);
}

export default {
  login,
  loginWithToken,
  register,
  logout,
  getToken,
  getCurrentUser,
  checkLogin,
  getMemberName,
  getUserId,
  verifyPhone,
};
