import React from "react";
import './checkbox.css';

const Checkbox = ({ name, label, error, ...rest }) => {
    return (
        <React.Fragment>
            <label id="switch">
                <input {...rest} name={name} id={name} />
                <span className="slider round"></span>
                {error && <small className="text-danger help-block">{error}</small>}
            </label>

        </React.Fragment>
    );
};

export default Checkbox;
