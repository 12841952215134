import axios from "axios";

const tokenKey = "We9MemberToken";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    //toast.error("An unexpected error occurrred.");
  } else if (error.response.status === 401) {
    localStorage.removeItem("We9MemberToken");
    const url = window.location.href.toString();
    if (!url.includes("/login" || "/register")) {
      window.location = "/login";
    }
  } else {
    //return "Server Error!";
  }

  return Promise.reject("Server Error!");
});

function setToken(token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

function removeToken() {
  axios.defaults.headers.common["Authorization"] = "Bearer " + null;
}

function setLanguage(languageId) {
  axios.defaults.headers.common["languageId"] = languageId;
}

var token = localStorage.getItem(tokenKey);
var languageId = localStorage.getItem("We8MemberLanguageId");
axios.defaults.headers.common["Authorization"] = "Bearer " + token;

if (languageId != null) {
  axios.defaults.headers.common["languageId"] = parseInt(languageId);
} else {
  axios.defaults.headers.common["languageId"] = 1;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
  setLanguage,
  removeToken,
};
