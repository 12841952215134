import React from "react";
import { css } from "@emotion/react";
// First way to import
import {
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  ClimbingBoxLoader,
  ClipLoader,
  DotLoader,
  FadeLoader,
  GridLoader,
  HashLoader,
  MoonLoader,
  PacmanLoader,
  PropagateLoader,
  PulseLoader,
  RingLoader,
  RiseLoader,
  RotateLoader,
  ScaleLoader,
  SkewLoader,
  SquareLoader,
  SyncLoader,
} from "react-spinners";

const override = css`
  display: block;
  margin: 2px auto;
  border-color: red;
`;

const Loading = (props) => {
  const { isLoad } = props;
  if (isLoad) {
    return (
      <div className="text-center p-4">
        <BeatLoader
          css={override}
          sizeUnit={"px"}
          size={15}
          color={"#581c8c"}
          loading={true}
        />
      </div>
    );
  }
  return null;
};

export default Loading;
