import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import HomeIcon from "@iconify/icons-mdi/home";
import CtgIcon from "@iconify/icons-mdi/view-grid";
import ShopIcon from "@iconify/icons-mdi/shop";
import UserIcon from "@iconify/icons-mdi/user";
import CommonContext from "../../context/CommonContext";

class BottomNavigation extends Component {
  static contextType = CommonContext;
  render() {
    const { languageData } = this.context;
    return (
      <div className="bottom-nav">
        <NavLink to="/home" className="nav-link" activeClassName="active">
          <InlineIcon icon={HomeIcon} className="nav-icon" />
          <span className="nav-label">Home</span>
        </NavLink>
        <NavLink to="/changePassword" className="nav-link" activeClassName="active">
          <span className="nav-label">Change Password</span>
        </NavLink>
        {/* <NavLink to="/cartList" className="nav-link" activeClassName="active">
          <InlineIcon icon={ShopIcon} className="nav-icon" />
          <span className="nav-label">{languageData.shop}</span>
        </NavLink>
        <NavLink to="/cartList" className="nav-link" activeClassName="active">
          <InlineIcon icon={UserIcon} className="nav-icon" />
          <span className="nav-label">{languageData.profile}</span>
        </NavLink> */}
      </div>
    );
  }
}

export default BottomNavigation;
