import React from "react";

const RadioGroup = ({
  name,
  label,
  options,
  error,
  data,
  checked,
  value,
  className,
  ...rest
}) => {
  return (
    <React.Fragment>
      {options.map((item) => (
        <label
          key={item.id}
          className={className}
          style={{
            marginRight: 15,
          }}
        >
          <input
            name={name}
            id={item.id}
            type="radio"
            value={item.id}
            checked={value == item.id ? true : false}
            {...rest}
          />
          {item.name}
        </label>
      ))}

      {error && <small className="text-danger help-block">{error}</small>}
    </React.Fragment>
  );
};

export default RadioGroup;
