import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

const IconInput = ({ name, label, error, iconName, ...rest }) => {
  return (
    <React.Fragment>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={iconName} />
          </InputGroupText>
        </InputGroupAddon>
        <input
          {...rest}
          name={name}
          id={name}
          placeholder={label}
          className="form-control"
        />
      </InputGroup>
      {error && <div className="text-danger"><small>{error}</small></div>}
    </React.Fragment>
  );
};

export default IconInput;
