import React from "react";
import "./select.css";

const SelectWithOptionGroup = ({ name, label, options, error, ...rest }) => {
  return (
    <React.Fragment>
      <select
        name={name}
        id={name}
        {...rest}
        className="custom-select form-control"
      >
        <option value="0" />
        <optgroup label="Choose">
          {options.map(option => {
            if (option.option) {
              if (option.option === "Yes") {
                return (
                  <option id="selectOption" key={option.id} value={option.id}>
                    {option.name}
                  </option>
                );
              }
            }
          })}
        </optgroup>
        <optgroup label="Input">
          {options.map(option => {
            if (option.option) {
              if (option.option === "No") {
                if (option.id === 6 || option.id === 7) {
                  return (
                    <option id="selectOption" key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  );
                }
              }
            }
          })}
        </optgroup>
        <optgroup label="File">
          {options.map(option => {
            if (option.option) {
              if (option.option === "No") {
                if (option.id === 11) {
                  return (
                    <option id="selectOption" key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  );
                }
              }
            }
          })}
        </optgroup>
        <optgroup label="Date">
          {options.map(option => {
            if (option.option) {
              if (option.option === "No") {
                if (option.id === 8 || option.id === 9 || option.id === 10) {
                  return (
                    <option id="selectOption" key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  );
                }
              }
            }
          })}
        </optgroup>
      </select>
      {error && <small className="text-danger help-block">{error}</small>}
    </React.Fragment>
  );
};

export default SelectWithOptionGroup;
