import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = ({ name, label, value, error, ...rest }) => {
  return (
    <React.Fragment>
      <DatePicker
        {...rest}
        selected={value}
        name={name}
        id={name}
        className="form-control"
      />

      {error && <small className="text-danger help-block">{error}</small>}
    </React.Fragment>
  );
};

export default DateInput;
