import React from "react";
import Form from "../../../components/common/form/form";
import logo from "../../../assets/logo.png";
import { withRouter, Link } from "react-router-dom";
import { Input, Row, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import Joi from "joi-browser";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import InlineIcon from "@iconify/react";
import Fade from "react-reveal/Fade";
import DoubleArrowLeft from "@iconify/icons-mdi/chevron-double-left";

import { CommonContext } from "../../../context/CommonContext";
import auth from "../../../components/auth/authService";
import SuccessModal from "../../../components/common/modal/modal";
//const phoneRegex = /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;
const codeRegex = /^[0-9]{6}$/;
const phoneRegex = /^[0-9]{9,15}$/;
const passRegex = /^[0-9]{6}$/;

class RegisterView extends Form {
  //static contextType = CommonContext;
  state = {
    data: {
      name: "",
      birthDate: "",
      gender: "",
      address: "",
      referrerId: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    code: "",
    days: [],
    mMonths: [],
    mYears: [],
    birthDay: {
      date: "",
      month: "",
      year: "",
    },
    genders: [
      { id: "male", name: "Male" },
      { id: "female", name: "Femal" },
    ],
    errors: {},
    isLoad: false,
    refererName: "",
    isModal: false,
    modalContent: "",
    //secCount: secondCount,
    isSendCodeLoad: false,
    expireTime: undefined,
    isExpired: false,
    sentCode: false,
    isFirstStep: true,
    isSecondStep: false,
    phoneIntervalId: undefined,
    isShowModel: false,
  };

  schema = {
    name: Joi.string()
      .required()
      .error(() => {
        return { message: "Name is required." };
      }),
    birthDate: Joi.date()
      .required()
      .error(() => {
        return { message: "Birth date is not valie." };
      }),
    gender: Joi.string()
      .required()
      .error(() => {
        return { message: "Gender is required." };
      }),
    address: Joi.any().allow("", null),
    referrerId: Joi.string().min(8).max(8).required(),
    email: Joi.any().allow("", null),
    phone: Joi.string()
      .regex(phoneRegex)
      .required()
      .error(() => {
        return { message: "Phone number must be correct." };
      }),
    password: Joi.string()
      .regex(passRegex)
      .required()
      .error(() => {
        return { message: "Password must be 6." };
      }),
    password: Joi.string()
      .regex(passRegex)
      .required()
      .error((errors) => {
        return errors.map((error) => {
          switch (error.type) {
            case "string.regex.base":
              return { message: "Password must be 6 number." };
            case "any.empty":
              return { message: "Password is required." };
            default:
              return null;
          }
        });
      }),
    confirmPassword: Joi.string()
      .valid(Joi.ref("password"))
      .required()
      .error(() => {
        return { message: "Passwords do not match" };
      }),
  };

  componentDidMount = () => {
    if (auth.getToken()) {
      this.props.history.replace("/");
    } else {
      var curentYear = new Date().getFullYear();
      var currentMonth = new Date().getMonth() + 1;
      var lastYear = curentYear - 100;
      var mYears = [];
      var days = [];
      for (var i = curentYear; i >= lastYear; i--) {
        mYears.push(i);
      }
      var mMonths = moment.months();
      var daysInMonth = moment(
        curentYear + "-" + currentMonth,
        "YYYY-M"
      ).daysInMonth();
      for (var i = 1; i <= daysInMonth; i++) {
        days.push(i);
      }
      this.setState({ mYears, mMonths, days });
    }
  };

  handleChangeDate(e) {
    const { birthDay } = this.state;
    birthDay.date = e.target.value;
    this.setState({ birthDay }, this.handleBirthDateChange);
  }

  loadDays = (month, year) => {
    var daysInMonth = moment(year + "-" + month, "YYYY-M").daysInMonth();
    var days = [];
    for (var i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }
    this.setState({ days });
  };

  handleBirthDateChange() {
    const { birthDay, data, errors } = this.state;
    var date = new Date();
    date.setFullYear(birthDay.year);
    date.setDate(birthDay.date);
    date.setMonth(birthDay.month - 1);
    data.birthDate = date;
    const obj = { ["birthDate"]: data.birthDate };
    const schema = { ["birthDate"]: this.schema["birthDate"] };
    const { error } = Joi.validate(obj, schema);
    errors["birthDate"] = error ? error.details[0].message : null;
    this.setState({ data, errors });
  }

  handleChangeMonth = async (e) => {
    const { value } = e.target;
    if (value !== null && value != "") {
      const { birthDay, data } = this.state;
      await this.loadDays(value, birthDay.year);
      if (!this.state.days.includes(Number(birthDay.date))) {
        birthDay.date = 1;
      }
      birthDay.month = value;
      this.setState({ birthDay }, this.handleBirthDateChange);
      console.log(data);
    }
  };

  handleYearChange = async (e) => {
    const { value } = e.target;
    if (value != null && value != "") {
      const { birthDay, days } = this.state;
      await this.loadDays(birthDay.month, value);
      if (!this.state.days.includes(Number(birthDay.date))) {
        birthDay.date = 1;
      }
      birthDay.year = value;

      this.setState({ birthDay }, this.handleBirthDateChange);
    }
  };

  handleAddressChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    const { name, value } = input;
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    data[input.name] = input.value;
    this.setState({ data, errors });
    // if (input.name == "confirmPassword") {
    //   if (data.password != undefined) {
    //     if (data[input.name] === data.password) {
    //       delete errors[input.name];
    //     }
    //   }
    // }
    this.setState({ data, errors }, () => {
      if (name === "address") {
        const obj = { ["birthDate"]: data.birthDate };
        const schema = { ["birthDate"]: this.schema["birthDate"] };
        const { error } = Joi.validate(obj, schema);
        errors["birthDate"] = error ? error.details[0].message : null;
        this.setState({
          errors,
          data,
        });
      }
    });
  };

  handleRefererIdChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    const { name, value } = input;
    const errorMessage = this.validateProperty(input);

    if (errorMessage == null) {
      this.setState({ isLoad: true });
      try {
        const { data: userData } = await auth.getMemberName(value);
        this.setState({ refererName: userData.data.name, isLoad: false });
      } catch (ex) {
        this.setState({ refererName: "No member", isLoad: false });
      }
    }
    if (errorMessage) errors[input.name] = "Add correct referrer Id.";
    else delete errors[input.name];
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    this.setState({ isLoad: true });
    const { languageData } = this.state;
    try {
      let { data } = this.state;
      let response = await auth.register(data);
      if (data["code"] != null || response.data.success != false) {
        let modalContent = "We already sent verification code to your phone.";
        this.setState({ isModal: true, modalContent });
      } else {
        NotificationManager.error(response.data.error, "Error!");
      }
    } catch (ex) {
      NotificationManager.error("Server error!", "Error");
    }
    this.setState({ isLoad: false });
  };

  verifyPhone = async () => {
    this.setState({ isLoad: true });
    const { code, data } = this.state;
    try {
      let response = {};

      let config = {
        phone: data.phone,
        code: code,
      };
      response = await auth.verifyPhone(config);
      console.log(response);
      if (response.data.status) {
        NotificationManager.success("Success", "success");
        this.props.history.push({
          pathname: "/login",
        });
      } else {
        NotificationManager.error("Unknow error", "Error");
        this.setState({
          submitButtonLoad: false,
          isLoad: false,
        });
      }
    } catch (ex) {
      if (ex) {
        NotificationManager.error(ex, "Error");
        this.setState({ isLoad: false, submitButtonLoad: false });
      }
    }
  };

  conFirmOk = () => {
    //this.props.history.replace("/login");
    this.setState({ isShowModel: true });
  };

  toggle = () => {
    //this.props.history.replace("/login");
    this.setState({ isShowModel: true });
  };

  renderVerifyForm() {
    const { isLoad, code } = this.state;
    return (
      <React.Fragment>
        <>
          <section className="content-section bg-purple">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="register-info">
                    <div className="d-flex justify-content-center">
                      <img
                        className="register-image rounded-circle mt-3 mb-4"
                        src={logo}
                      />
                    </div>
                    <div className="register-form">
                      <div>
                        <div className="form-group">
                          <label htmlFor="verify_code" className="text-white">
                            Verification Code
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            name="code"
                            id="code"
                            value={code}
                            onChange={(e) =>
                              this.setState({ code: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-login btn-block mr-2"
                            onClick={() => this.verifyPhone()}
                          >
                            {isLoad ? (
                              <i className="fa fa-refresh fa-spin mr-2" />
                            ) : (
                              <i className="fa fa-check mr-2" />
                            )}
                            Verify
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </React.Fragment>
    );
  }

  renderRegisterForm() {
    const {
      birthDay,
      days,
      mMonths,
      mYears,
      isLoad,
      errors,
      genders,
      data,
      refererName,
      isModal,
      modalContent,
    } = this.state;
    return (
      // Register with Email
      <React.Fragment>
        <>
          <section className="content-section bg-purple">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="register-info">
                    <div className="d-flex justify-content-center">
                      <img
                        className="register-image rounded-circle mt-3 mb-4"
                        src={logo}
                      />
                    </div>

                    <div className="register-form">
                      <div>
                        <form onSubmit={this.handleSubmit}>
                          <div className="form-group">
                            <label htmlFor="name" className="text-white">
                              Name
                            </label>
                            {this.renderInput("name", "name", "")}
                          </div>
                          <div className="form-group">
                            <label htmlFor="birthDate" className="text-white">
                              Birth Date
                            </label>
                            <Row className="form-row">
                              <Col xs={{ size: 4 }}>
                                <Input
                                  type="select"
                                  name="select"
                                  id="birthDay"
                                  onChange={(e) => this.handleChangeDate(e)}
                                  defaultValue={birthDay.date}
                                >
                                  {days.map((day) => {
                                    return (
                                      <option value={day} key={day}>
                                        {day}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </Col>
                              <Col xs={{ size: 4 }}>
                                <Input
                                  type="select"
                                  name="select"
                                  id="month"
                                  onChange={(e) => this.handleChangeMonth(e)}
                                  defaultValue={birthDay.month}
                                >
                                  {mMonths.map((month, index) => {
                                    return (
                                      <option value={index + 1} key={index}>
                                        {index + 1}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </Col>
                              <Col xs={{ size: 4 }}>
                                <Input
                                  type="select"
                                  name="select"
                                  id="year"
                                  onChange={(e) => this.handleYearChange(e)}
                                  defaultValue={birthDay.year}
                                >
                                  {mYears.map((year) => {
                                    return (
                                      <option value={year} key={year}>
                                        {year}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </Col>
                            </Row>
                            {errors["birthDate"] && (
                              <div className="text-danger help-block">
                                <small>{errors["birthDate"]}</small>
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="gender" className="text-white mr-3">
                              Gender
                            </label>
                            {this.renderRadioGroup(
                              "gender",
                              "Gender",
                              genders,
                              data.gender,
                              "text-white"
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="address" className="text-white">
                              Address
                            </label>
                            <textarea
                              value={data.address}
                              className="form-control border h-10"
                              rows="5"
                              name="address"
                              onChange={this.handleAddressChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name" className="text-white">
                              ReferrerId
                            </label>
                            {/* {this.renderInput("referrerId", "ReferrerId", "")} */}
                            <input
                              className="form-control"
                              name="referrerId"
                              id="referrerId"
                              onChange={this.handleRefererIdChange}
                            />
                            {refererName !== "" && (
                              <div className="text-success help-block">
                                <small>{"Member Name : " + refererName}</small>
                              </div>
                            )}
                            {errors["referrerId"] && (
                              <div className="text-danger help-block">
                                <small>{errors["referrerId"]}</small>
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="email" className="text-white">
                              Email
                            </label>
                            {this.renderInput("email", "Email")}
                          </div>
                          <div className="form-group">
                            <label htmlFor="phone" className="text-white">
                              Phone No
                            </label>
                            {this.renderInput("phone", "Phone", "09771121212")}
                          </div>
                          <div className="form-group">
                            <label htmlFor="password" className="text-white">
                              Password
                            </label>
                            {this.renderInputPassword("password", "Password")}
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="confirmPassword"
                              className="text-white"
                            >
                              Confirm Password
                            </label>
                            {this.renderInputPassword(
                              "confirmPassword",
                              "Confirm Password"
                            )}
                          </div>
                          <div className="form-group">
                            {this.renderButton(
                              "Register",
                              "btn btn-login btn-block mr-2",
                              isLoad
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={isModal}
              toggle={this.toggle}
              className="modal-dialog-centered"
              id="modal-dialog"
            >
              <ModalHeader>
                <h4 className="text-success text-center">Success</h4>
              </ModalHeader>
              <ModalBody>
                <div className="text-center">
                  {/* <img src={wrongImg} className="modal-icon" alt="" /> */}
                </div>
                {/* <div className="d-flex justify-content-center align-items-center mb-2">
                  <span className="alert-icon danger">
                    <InlineIcon icon={CloseIcon} className="icon" />
                  </span>
                </div> */}
                <p className="modal-text text-center mb-2">{modalContent}</p>
                <button
                  className="btn btn-sm btn-success btn-block"
                  onClick={() => this.conFirmOk()}
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
          </section>
        </>
      </React.Fragment>
    );
  }

  render() {
    const { isShowModel } = this.state;
    return (
      <div>
        {!isShowModel ? this.renderRegisterForm() : this.renderVerifyForm()}
      </div>
    );
  }
}
export default withRouter(RegisterView);
