import React from "react";

const TextArea = ({ name, label, error, ...rest }) => {
  return (
    <React.Fragment>
      <textarea
        {...rest}
        className="form-control border h-10"
        rows="5"
        name={name}
      />
      {error && (
        <div className="text-danger help-block">
          <small>{error}</small>
        </div>
      )}
    </React.Fragment>
  );
};

export default TextArea;
